
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import {
  AutocompleteItem,
  EntityType, ISearchedItems
} from '../../../types/surcouche_v2_types'
import { getNewIoField } from '../../../utils/instructionsUtils'
import store from '../../../store/index.js'
import { common } from '../../../store/modules/common'

if (!store.state.common) store.registerModule('common', common)

const MINIMUM_CHARACTERS_TO_START_SEARCHING = 3 // Arbitrary, but 3 is nice :)

export type HeaderType = {
  header: string
}

export type DividerType = {
  divider: boolean
  dividerType: EntityType
}

export type AutocompleteSearchBarItem = HeaderType | DividerType | AutocompleteItem

export default defineComponent({
  name: 'AutocompleteSearchBar',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showExternalID: {
      type: Boolean,
      required: false,
      default: false
    },
    showInternalID: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedItemSync: {
      type: Object as PropType<Array<AutocompleteItem>>,
      required: false
    }
  },
  components: {},
  data () {
    return {
      selectedItem: this.selectedItemSync,
      // Search timeout
      autoSearchTimeout: '',
      timeoutHandle: null,
      dataWasSearched: false // Flag to say if previous operation started a search
    }
  },
  mounted () {},
  methods: {
    getKeystoneItemName (item: AutocompleteItem) {
      return item.name
    },
    itemTextFunction (item: AutocompleteItem) {
      const reg = new RegExp(this.autoSearch, 'gi')

      const highlightTemplate = function (template: string | number) {
        const templateValue = typeof template === 'string' ? template : String(template)
        const result = String(templateValue).match(reg)
        if (Array.isArray(result) && result.length) {
          const resultCleaned = [...new Set(result.map(item => String(item).toLowerCase()))]
          let templateCopy = templateValue
          for (let i in resultCleaned) {
            let reg = new RegExp(result[i], 'g')
            templateCopy = templateValue.replace(reg, `<span class="highlight-auto">${result[i]}</span>`)
          }
          return templateCopy
        }
        return templateValue
      }

      const internalID = this.showInternalID && Object.keys(item).includes('id') && item.id != null
        ? ` <span class="id-autocomplete">${highlightTemplate(item.id)}</span>`
        : ''

      const externalID = this.addExternalIdText(item)

      const complementaryData = `${externalID}${internalID}`

      // If "group_name" exists in item data, it means it is an instruction and the key for the name is called "group_name" in this case
      const itemName = item.name

      return `${highlightTemplate(itemName)}${complementaryData}`
    },
    // utils
    resetSearch () {
      console.warn('resetAutoResult')
      this.$store.dispatch('surcoucheV2SearchCancelled')
      this.$store.dispatch('resetSurcoucheV2SearchedItems')
      this.autocompleteItems = []
      this.selectedItem = {} as AutocompleteItem[]
      this.autoSearch = ''
      this.autoSearchTimeout = ''
      this.$emit('searchChanged')
    },
    isObject (value: any) {
      return typeof value === 'object'
    },
    addExternalIdText (item: AutocompleteItem): string {
      const dsp = this.$store.getters.getSurcoucheV2SearchParamsFilters.dsp
      if (this.showExternalID && dsp != null) {
        const ioField = getNewIoField(dsp.toLowerCase()) as keyof AutocompleteItem
        if ('external_id' in item && item.external_id != null) {
          return ` (${item.external_id})`
        } else if (ioField in item && item[ioField] != null) {
          return ` (${item[ioField]})`
        }
      }
      return ''
    },
    selectItem (value: AutocompleteItem) {
      this.$emit('selectItem', value)
    },
    selectPartialItem () {
      console.warn('selectPartialItem not implemented yet.')
      const barConfig = {
        type: 'warning',
        message: 'Not implemented yet.'
      }
      this.$store.commit('setSnackBarConfig', barConfig)
      this.$store.commit('setActiveSnackBar', true)
    }
  },
  computed: {
    autoSearch: {
      get (): string {
        return this.$store.getters.getSurcoucheV2SearchParamsFilters.search
      },
      set (newValue: string) {
        this.$store.commit('setSurcoucheV2SearchParamsFilters', { filter: 'search', value: newValue })
      }
    },
    autocompleteItems: {
      get (): Array<AutocompleteSearchBarItem> {
        const autocompleteItems: Array<AutocompleteSearchBarItem> = []
        const entityGroup: ISearchedItems = this.$store.getters.getSurcoucheV2SearchedItems

        for (let key in entityGroup) {
          const entityKey = key as keyof ISearchedItems
          if (!entityGroup.hasOwnProperty(entityKey) || !entityGroup[entityKey].hasOwnProperty('type')) {
            continue
          }
          let type: EntityType = entityGroup[entityKey].type
          let group = entityGroup[entityKey]
          let entities: Array<AutocompleteItem> = entityGroup[entityKey].entities
          const header: HeaderType = {
            header: type === 'siege'
              ? 'Seats'
              : `${this.$commonUtils.camelCaseToCapitalizedWordsString(type)}` }
          const divider: DividerType = { divider: true, dividerType: type }
          if (group.count) {
            autocompleteItems.push(header)
            autocompleteItems.push(...entities)
            autocompleteItems.push(divider)
          }
        }
        return autocompleteItems
      },
      set (value: Array<AutocompleteSearchBarItem>) {
        return value
      }
    }
  },
  watch: {
    autoSearchTimeout: function (autoSearchTimeout) {
      if (!autoSearchTimeout) {
        if (this.dataWasSearched) {
          this.resetSearch()
          this.dataWasSearched = false
        }
        return
      }

      if (this.timeoutHandle !== null) {
        clearTimeout(this.timeoutHandle)
      }

      this.timeoutHandle = window.setTimeout(() => {
        this.autoSearch = autoSearchTimeout.trim()
      }, 400)
    },
    autoSearch: function (autoSearch) {
      if (autoSearch == null || autoSearch.length === 0) {
        this.resetSearch()
      } else if (autoSearch && autoSearch.length >= MINIMUM_CHARACTERS_TO_START_SEARCHING) {
        this.$store.dispatch('setupSurcoucheV2AutocompleteItems')
        this.dataWasSearched = true
      }
    },
    selectedItem: function (item: AutocompleteItem) {
      this.$emit('update:selectedItemSync', item)
    },
    selectedItemSync: function (item) {
      this.selectedItem = this.selectedItemSync
    }
  }
})

