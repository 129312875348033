import { render, staticRenderFns } from "./SurcoucheV2.vue?vue&type=template&id=3e82b9b3&scoped=true"
import script from "./SurcoucheV2.vue?vue&type=script&lang=ts"
export * from "./SurcoucheV2.vue?vue&type=script&lang=ts"
import style0 from "./SurcoucheV2.vue?vue&type=style&index=0&id=3e82b9b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e82b9b3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems})
